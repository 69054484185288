<template>
  <div class="player-container">
    <video
      ref="player"
      id="player"
      class="player"
      :poster="poster ? resetUrl(poster) : lazy"
      :src="src"
      autoplay
      controls
      controlslist="nodownload"
      x5-video-player-fullscreen="true"
      webkit-playsinline="true"
      playsinline
      x5-playsinline="true"
      x-webkit-airplay="true"
    />
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: ""
    },
    poster: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      lazy: require("@/assets/img/lazy-loading.png")
    };
  },
  methods: {
    resetUrl(url) {
      if (!url) {
        return;
      }
      return url.replace("http://qiniu", "https://qiniu");
    },
    load(src) {
      this.$refs.player.src = src;
      this.$refs.player.load();
    },
    pause() {
      this.$refs.player.src = "";
      this.$refs.player.load();
    }
  },
  beforeDestroy() {
    this.pause();
  }
};
</script>
<style lang="stylus" scoped>
.player-container
  width 100%
  font-size 0
  .player
    position relative
    width 100%
    height calc(100vw / (16/9))
    z-index 9
</style>
