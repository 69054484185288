<template>
  <transition name="slide">
    <div class="video-details">
      <h :isback="true">
        <span class="name" v-text="video.name" />
      </h>
      <player ref="player" :src="video.url" :poster="video.image" />
      <h3 class="desc">
        <span class="number">第{{ video.number }}集</span>
        <span>{{ video.name }}</span>
      </h3>
      <div v-show="type != 3" class="top">
        <span class="tag">选集</span>
        <span v-if="maxShowIndex == 7" class="btn" @click="extend"
          >展开<i class="cubeic-select" />
        </span>
        <span v-else class="btn" @click="packUp"
          >收起<i class="cubeic-select up" />
        </span>
      </div>
      <ul v-show="type != 3" class="list">
        <li
          v-for="(item, index) in list"
          :key="item.id"
          :class="{ active: index == videoIndex }"
          v-show="index <= maxShowIndex"
          @click="change(item, index)"
        >
          <span v-text="item.number" class="number" />
          <span v-if="item.roleStatus == 2" class="vip">
            VIP
          </span>
        </li>
      </ul>
    </div>
  </transition>
</template>
<script>
import h from "@/components/base/header";
import player from "@/components/player/player";
import { mapState, mapMutations } from "vuex";
import {
  getVideoList,
  getNormalList,
  getVideoDetails,
  buyVideo
} from "@/api/video";
export default {
  components: {
    h,
    player
  },
  data() {
    return {
      list: [],
      video: {},
      videoIndex: 0,
      maxShowIndex: 7,
      currentTime: 0
    };
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
      user: state => state.user.info
    }),
    type() {
      return parseInt(this.$route.query.type);
    },
    number() {
      return parseInt(this.$route.query.number);
    },
    id() {
      return parseInt(this.$route.params.id);
    }
  },
  watch: {
    video(video, old) {
      if (video.videoId == old.videoId) {
        return;
      }
      this.ispay();
      this.isVip();
      this.listener();
    }
  },
  created() {
    if (!this.token) {
      this.$router.push("/login");
      return;
    }
    if (this.type == 0) {
      this._getNormalList();
    } else if (this.type == 3) {
      this._getVideoDetails();
    } else {
      this._getVideoList();
    }
    window.addEventListener("unload", () => {
      this.addWatchHistory();
    });
  },
  beforeDestroy() {
    this.video.url = "";
    this.addWatchHistory();
  },
  methods: {
    ...mapMutations({
      toast: "global/SET_TOAST"
    }),
    listener() {
      const el = this.$refs.player.$el.querySelector("video");
      el.currentTime = this.video.playPos / 1000;
      console.log(this.video.playPos);
      el.addEventListener("ended", () => {
        this.next();
      });
      el.addEventListener("play", () => {
        this.ispay();
        this.isVip();
      });
    },
    isVip() {
      if (this.video.roleStatus == 2 && this.user.userType != 2) {
        this.video.url = "";
        this.$createDialog({
          type: "confirm",
          icon: "cubeic-alert",
          title: "提示",
          content: "本视频为会员视频，开通会员才能观看",
          confirmBtn: {
            text: "去开通",
            active: true,
            disabled: false,
            href: "javascript:;"
          },
          cancelBtn: {
            text: "取消",
            active: false,
            disabled: false,
            href: "javascript:;"
          },
          onConfirm: () => {
            this.$router.push("/member/vip");
          }
        }).show();
        return;
      }
    },
    ispay() {
      if (this.video.videoType == 3 && !this.video.isPay) {
        this.video.url = "";
        this.$createDialog({
          type: "confirm",
          icon: "cubeic-alert",
          title: "提示",
          content: "本视频为金币视频，解锁后可永久观看",
          confirmBtn: {
            text: "金币解锁",
            active: true,
            disabled: false,
            href: "javascript:;"
          },
          cancelBtn: {
            text: "取消",
            active: false,
            disabled: false,
            href: "javascript:;"
          },
          onConfirm: () => {
            this._buyVideo();
          }
        }).show();
        return;
      }
    },
    _buyVideo() {
      const params = {
        videoId: this.video.id
      };
      buyVideo(params).then(res => {
        if (!res) {
          return;
        }
        if (res.object.code == "0") {
          this.toast(res.object.message);
          return;
        }
        this._getVideoDetails();
      });
    },
    _getVideoList() {
      const params = {
        categoryId: this.id,
        clientType: 3,
        pageNumber: 1,
        pageSize: 100
      };
      getVideoList(params).then(res => {
        if (!res) {
          return;
        }
        this.list = res.object.videoList;
        if (this.list.length > 0) {
          const newList = [];
          this.list.forEach(ele => {
            newList[ele.number - 1] = ele;
          });
          for (let i = 0; i < newList.length; i++) {
            if (newList[i] == undefined) {
              newList[i] = {
                number: i + 1,
                url: "",
                name: "",
                videoType: 1
              };
            }
          }
          this.list = newList;
        }
        if (this.type == 1) {
          this.videoIndex = this.list.findIndex(item => {
            return this.number == item.videoId;
          });
          this.video = this.list[this.videoIndex];
        } else {
          this.videoIndex = this.list.findIndex(item => {
            return item.playPos > 0;
          });
          this.videoIndex = this.videoIndex > -1 ? this.videoIndex : 0;
          this.video = this.videoIndex
            ? this.list[this.videoIndex]
            : this.list[0];
        }
      });
    },
    _getNormalList() {
      const params = {
        categoryId: 0,
        clientType: 3,
        pageNumber: 1,
        pageSize: 20
      };
      getNormalList(params).then(res => {
        if (!res) {
          return;
        }
        this.list = res.object.videoList;
        if (this.list.length > 0) {
          const newList = [];
          this.list.forEach(ele => {
            newList[ele.number - 1] = ele;
          });
          for (let i = 0; i < newList.length; i++) {
            if (newList[i] == undefined) {
              newList[i] = {
                number: i + 1,
                url: "",
                name: "",
                videoType: 1
              };
            }
          }
          this.list = newList;
        }
        this._getVideoDetails();
      });
    },
    _getVideoDetails() {
      const params = {
        id: this.id
      };
      getVideoDetails(params).then(res => {
        if (!res) {
          return;
        }
        this.video = res.object;
      });
    },
    extend() {
      this.maxShowIndex = this.list.length - 1;
    },
    packUp() {
      this.maxShowIndex = 7;
    },
    change(item, index) {
      if (!item.url) {
        this.toast("暂无资源");
        return;
      }
      this.video = item;
      this.videoIndex = index;
    },
    vipAlert() {
      this.$createDialog({
        type: "confirm",
        icon: "cubeic-alert",
        title: "提示",
        content: "下集视频为会员视频，开通会员后可观看",
        confirmBtn: {
          text: "开通会员",
          active: true,
          disabled: false,
          href: "javascript:;"
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;"
        },
        onConfirm: () => {
          this.$router.push("/member/vip");
        }
      }).show();
    },
    next() {
      if (this.list.length <= 0) {
        return;
      }
      let nextIndex = this.list.findIndex(item => {
        return this.video.videoId == item.videoId;
      });
      if (nextIndex < 0) {
        nextIndex = 0;
      }
      nextIndex = nextIndex < this.list.length - 1 ? nextIndex + 1 : nextIndex;
      if (nextIndex == this.list.length - 1) {
        this.$createDialog({
          type: "alert",
          title: "提示",
          content: "本系列已播放完毕",
          icon: "cubeic-alert"
        }).show();
        return;
      }
      if (this.list[nextIndex].roleStatus == 2 && this.user.userType != 2) {
        this.vipAlert();
        return;
      }
      if (!this.list[nextIndex].url) {
        return;
      }
      this.video = this.list[nextIndex];
      this.videoIndex = nextIndex;
    },
    addWatchHistory() {
      const el = this.$refs.player.$el.querySelector("video");
      this.currentTime = el.currentTime * 1000;
      if (this.currentTime <= 0 || !this.token) {
        return;
      }
      const url = `
        ${process.env.VUE_APP_API}:8088/admin/api/live_video/liveVideoWatchHistoryGET.json?clientType=3&userId=${this.user.id}&playPos=${this.currentTime}&videoId=${this.video.id}
      `;
      const scr = new Image();
      scr.src = url;
      scr.style.display = "none";
      document.body.appendChild(scr);
    }
  }
};
</script>
<style lang="stylus" scoped>
@import "~assets/stylus/mixin.styl";
.video-details
  position fixed
  top 0
  right 0
  bottom 0
  left 0
  background #fff
  z-index 10
  .desc
    margin 19px 16px 35px
    font-size 13px
    color #383A40
    .number
      margin-right 10px
  .top
    margin 0 31px
    .tag
      font-size 13px
      color #383A40
    .btn
      float right
      color #909090
      .up
        display inline-block
        transform rotate(180deg)
  .list
    margin 19px 16px 0
    li
      position relative
      display inline-block
      width 29px
      height 29px
      line-height 29px
      margin 0 15px 16px 0
      background #E9ECED
      text-align center
      &.active
        background #333
        .number
          color #fff
      &:nth-of-type(8n+0)
        margin-right 0
      .number
        font-size 15px
        color #282828
      .vip
        position absolute
        top -4px
        right -4px
        width 28px
        height 14px
        line-height 14px
        background #BA9877
        border-radius 4px
        font-style normal
        font-size 10px
        color #fff
        text-align center
        transform scale(.6)
</style>
